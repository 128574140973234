import React from "react"

import Layout from "../components/layout"
import Meta from "../components/seo"

import MapWithAKmlLayer from "../components/google-map"

const Services = () => (
  <Layout>
    <Meta title="Delivery Area"
          description="Locally Fresh Cut & Delivered to your bakery, restaurant, hotel, wholesale bakery, food distributor, nursing home, college, and more!"
          url="https://www.greenvilleappleproducts.com/service-area" 
          />
    <div className="container pad">
    <article className="prose gray py-4">
      <h1>Service Area</h1>
      <p>Locally Fresh Cut & Delivered to your bakery, restaurant, hotel, wholesale bakery, food distributor, nursing home, college, and more!</p>
    </article>
    </div>
    <div className="relative">
    <MapWithAKmlLayer />
    {/* <iframe src="https://www.google.com/maps/d/embed?mid=15Oc0Vw0xWM58Nu8ew8fdQ6ddYOZQ-Uc&ehbc=2E312F" width="100%" height="600"></iframe> */}
    {/* <iframe src="https://snazzymaps.com/embed/421357" width="100%" height="600px" style={{ border:'none'}}></iframe> */}
    </div>
    {/* <iframe src="https://www.google.com/maps/d/embed?mid=15Oc0Vw0xWM58Nu8ew8fdQ6ddYOZQ-Uc&ehbc=2E312F" width="100%" height="600px" style={{ border:'none'}}></iframe> */}
  </Layout>
)

export default Services
