import React from "react";
import GoogleMapReact from "google-map-react";

const GOOGLE_API_KEY = "AIzaSyBOZ9ie3mI9G_Wb3-hMbBx0q3Xn0jL-19U";

export default function App() {
  return (
    <div className="App" style={{ width: "100%", height: "100%" }}>
      <GoogleMapReact
        style={{ width: "100vw", height: "100vh" }}
        bootstrapURLKeys={{
          key: GOOGLE_API_KEY,
          libraries: ["visualization"]
        }}
        defaultCenter={{
          lat: 41.872390,
          lng: -71.553950
        }}
        defaultZoom={9}
        yesIWantToUseGoogleMapApiInternals
      ></GoogleMapReact>
    </div>
  );
}
